'use client';

import React, { useEffect, useState, useTransition } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { signIn } from 'next-auth/react';

import {
  Alert,
  Input,
  Stack,
  Button,
  AlertIcon,
  AlertContent,
  InputPassword,
  ChevronRightIcon,
  Link as ArcadeLink,
} from '@whiteaway/ui';

import { isEmail } from '@/utils';
import { ROUTES } from '@/config';
import { useForm, useRouter } from '@/hooks';
import { useCart, useTranslations } from '@/contexts';
import { testIds } from '@/tests';

import { AuthFormHeader } from '../_components/auth-form-header';

const LOGGED_IN_ROUTE = ROUTES.PROTECTED.CATEGORIES;

/**
 * The signin page is used to sign a user in using AWS Amplify.
 */
export const SigninPageClient = () => {
  const [submitError, setSubmitError] = useState(false);

  const router = useRouter();

  const [loadingSignedInRoute, startRouteTransition] = useTransition();

  const { translations } = useTranslations();

  const { refetchCart } = useCart();

  const texts = translations.pages;
  const layoutTexts = translations.layouts.auth;

  const { handleSubmit, getFieldProps, submitting } = useForm({
    initialValues: { email: '', password: '' },
    validate: (values) => {
      setSubmitError(false);

      const errors: { email?: string; password?: string } = {};

      if (!isEmail(values.email)) errors.email = layoutTexts.emailEmpty;

      if (!values.password) errors.password = layoutTexts.passwordError;

      return errors;
    },
    onSubmit: async ({ email, password }) => {
      try {
        const response = await signIn('credentials', { email, password, redirect: false });

        if (!response || response.error) {
          throw new Error(response?.error);
        }

        // Refetch the cart, since it was empty to begin with.
        refetchCart();

        startRouteTransition(() => router.push(LOGGED_IN_ROUTE));
      } catch {
        setSubmitError(true);
      }
    },
  });

  // need to prefetch manually when using router programatically for a faster transition once logged in
  useEffect(() => router.prefetch(LOGGED_IN_ROUTE), [router]);

  return (
    <Stack spacing={5}>
      <AuthFormHeader header={texts.signin.header} subheader={texts.signin.subHeader} />

      <Stack asElement="form" onSubmit={handleSubmit}>
        <Input
          required
          type="email"
          label={layoutTexts.emailLabel}
          data-testid={testIds.pages.signin.emailInput}
          {...getFieldProps('email')}
        />

        <Stack spacing={1}>
          <InputPassword
            required
            label={layoutTexts.passwordLabel}
            data-testid={testIds.pages.signin.passwordInput}
            {...getFieldProps('password')}
          />

          <ForgotPasswordLink href={ROUTES.AUTH.FORGOTPASSWORD} asElement={Link}>
            {texts.signin.forgotPasswordLink}
          </ForgotPasswordLink>
        </Stack>

        {submitError && (
          <Alert status="error" variant="subtle">
            <AlertIcon />

            <AlertContent>{texts.signin.submitError}</AlertContent>
          </Alert>
        )}

        <Button
          size="lg"
          fullWidth
          type="submit"
          loading={submitting || loadingSignedInRoute}
          data-testid={testIds.pages.signin.loginButton}
        >
          <ChevronRightIcon />
          {texts.signin.loginButton}
        </Button>

        <Button variant="outlined" size="sm" color="neutral" asElement={Link} href={ROUTES.AUTH.SIGNUP} fullWidth>
          {texts.signin.createAccountButton}
        </Button>
      </Stack>
    </Stack>
  );
};

const ForgotPasswordLink = styled(ArcadeLink)`
  align-self: flex-start;
`;
