'use client';

import Image from 'next/image';
import styled, { css } from 'styled-components';

import { translations } from '@/contexts';
/**
 * The MobileLogo component is used to display the logo on mobile
 */
export const MobileLogo: React.FC = () => (
  <Root src="/logo.png" alt={translations.shared.logoAltText} width={294} height={68} />
);

MobileLogo.displayName = 'MobileLogo';

export default MobileLogo;

const Root = styled(Image)`
  ${({ theme }) => css`
    display: flex;

    ${theme.breakpoints.above('desktop')} {
      display: none;
    }
  `}
`;
