import { Stack, Typography } from '@whiteaway/ui';

import MobileLogo from './mobile-logo';

interface Props extends React.AnchorHTMLAttributes<HTMLDivElement> {
  /**
   * The header text for the form header
   */
  header: string;
  /**
   * The subheader text for the form header
   */
  subheader: string;
}

/**
 * The AuthFormHeader is used for displaying the header and subheader above the forms on the auth pages.
 * On mobile it will display a logo above the header.
 */
export const AuthFormHeader: React.FC<Props> = (props) => {
  const { header, subheader, ...rest } = props;

  return (
    <Stack spacing={4} align="center" {...rest}>
      <MobileLogo />

      <Typography textAlign="center" variant="h1">
        {header}
      </Typography>

      <Typography textAlign="center">{subheader}</Typography>
    </Stack>
  );
};

AuthFormHeader.displayName = 'AuthFormHeader';

export default AuthFormHeader;
