import { UseFormActions, UseFormState, UseFormValues } from './useForm.types';

export function useFormReducer<Values>(
  state: UseFormState<Values>,
  action: UseFormActions<Values>,
): UseFormState<Values> {
  switch (action.type) {
    case 'SET_FIELD_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      };

    case 'SET_FIELD_TOUCHED':
      return {
        ...state,
        touched: {
          ...state.touched,
          ...action.payload,
        },
      };

    case 'RESET': {
      return action.payload;
    }

    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };

    case 'SUBMIT_ATTEMPT':
      return {
        ...state,
        submitting: true,
        touched: Object.keys(state.values as UseFormValues).reduce((acc, key) => {
          // @ts-ignore
          acc[key] = true;
          return acc;
        }, {}),
      };

    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        submitting: false,
      };

    case 'SUBMIT_FAILED':
      return {
        ...state,
        submitting: false,
      };

    default:
      return state;
  }
}
