import React, { useCallback, useEffect, useMemo, useReducer } from 'react';

import { useFormReducer } from './useForm.reducer';
import { UseFormActions, UseFormProps, UseFormResponse, UseFormState, UseFormValues } from './useForm.types';

/**
 * The function is used to determine whether the form field contains a boolean (checkbox) value.
 */
const isCheckboxValue = (value: unknown) => {
  return typeof value === 'boolean';
};

/**
 * The useForm hook is used to keep track of a forms state in real time - including validation errors.
 */
export const useForm = <Values extends UseFormValues = UseFormValues>(
  props: UseFormProps<Values>,
): UseFormResponse<Values> => {
  const initialState = useMemo(
    () => ({
      values: props.initialValues,
      touched: {},
      errors: {},
      submitting: false,
    }),
    [props.initialValues],
  );

  const [state, dispatch] = useReducer<UseFormState<Values>, [UseFormActions<Values>]>(useFormReducer, initialState);

  const validateForm = useCallback(
    (values: Values) => {
      if (!props.validate) return;

      const errors = props.validate(values);

      dispatch({
        type: 'SET_ERRORS',
        payload: errors,
      });
    },
    [props],
  );

  const handleChange = useCallback((event: React.ChangeEvent<any>) => {
    event.persist();

    const { type, checked, value } = event.target;

    const nextValue = type === 'checkbox' ? checked : value;

    dispatch({
      type: 'SET_FIELD_VALUE',
      payload: { [event.target.name]: nextValue },
    });
  }, []);

  const handleBlur = useCallback((event: React.FocusEvent<any>) => {
    event.persist();

    dispatch({
      type: 'SET_FIELD_TOUCHED',
      payload: { [event.target.name]: true },
    });
  }, []);

  const handleReset = useCallback(() => {
    dispatch({
      type: 'RESET',
      payload: initialState,
    });
  }, [initialState]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLElement>) => {
      event.preventDefault();

      dispatch({ type: 'SUBMIT_ATTEMPT' });

      validateForm(state.values);

      if (Object.keys(state.errors).length) {
        return dispatch({ type: 'SUBMIT_FAILED' });
      }

      try {
        await props.onSubmit(state.values);
        dispatch({ type: 'SUBMIT_SUCCESS' });
      } catch {
        dispatch({ type: 'SUBMIT_FAILED' });
      }
    },
    [props, state.errors, state.values, validateForm],
  );

  const getFieldProps = useCallback(
    (name: keyof Values) => {
      const value = state.values[name];
      const error = state.errors[name];
      const touched = state.touched[name];

      return {
        name,
        onBlur: handleBlur,
        onChange: handleChange,
        invalid: error && touched ? true : undefined,
        value: !isCheckboxValue(value) ? value : undefined,
        error: !isCheckboxValue(value) ? error : undefined,
        checked: isCheckboxValue(value) ? value : undefined,
        valid: !isCheckboxValue(value) && !state.errors[name] && state.touched[name] ? true : undefined,
      };
    },
    [handleBlur, state.values, state.touched, state.errors, handleChange],
  );

  /**
   * Make sure that we validate the form each time the values change.
   */
  useEffect(() => {
    validateForm(state.values);
  }, [state.values]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ...state, handleChange, handleBlur, handleSubmit, getFieldProps, handleReset };
};

export default useForm;
